import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div fxLayoutAlign="center center" class="overlay">
      <mat-progress-spinner class="loader" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  `,
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {

  constructor() {
  }

}
