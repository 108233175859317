import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from '../core/http-request-interceptor';
import { AuthErrorInterceptor } from '../core/auth-error-interceptor';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LoaderComponent } from './loader/loader.component';

export const DATE_PATTERN = '^\\d{4}[\\/\\-](0?[1-9]|1[012])[\\/\\-](0?[1-9]|[12][0-9]|3[01])$';
export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    LoaderComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    NgxMaskModule.forRoot(options),
    NgxDaterangepickerMd.forRoot(),
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgxDaterangepickerMd,
    LoaderComponent
  ],
  providers: [
    [
      { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true }
    ],
  ],
})
export class SharedModule {
}
