import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface Notification {
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationSubject$ = new BehaviorSubject<Notification>(null);
  readonly notification$ = this.notificationSubject$.asObservable();

  constructor() { }

  setNotification(message: string): void {
    this.notificationSubject$.next({message});
  }
}
