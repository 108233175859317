import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {catchError, shareReplay, tap} from 'rxjs/operators';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject$ = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
  readonly user$ = this.userSubject$.asObservable();

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<User> {
    return this.http.post<User>(`${environment.api}/login`, {username, password}, {withCredentials: true}).pipe(
      tap((user) => this.userSubject$.next(user)),
      tap(user => localStorage.setItem('currentUser', JSON.stringify(user)))
    );
  }

  reloadUser(): Observable<User> {
    return this.http.get<User>(`${environment.api}/status`, {withCredentials: true}).pipe(
      tap((user) => this.userSubject$.next(user))
    );
  }

  logout(): Observable<{}> {
    return this.http.post<{}>(`${environment.api}/logout`, {}, {withCredentials: true}).pipe(
      catchError((e) => {
        this.userSubject$.next(null);
        return of({});
      }),
      tap(() => this.userSubject$.next(null))
    );
  }
}
