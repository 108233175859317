import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Overlay } from '@angular/cdk/overlay';
import { map, scan } from 'rxjs/operators';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from '../shared/loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private spinnerTopRef = this.cdkSpinnerCreate();

  loading$: Subject<boolean> = new Subject();

  constructor(
    private overlay: Overlay,
  ) {

    this.loading$
      .asObservable()
      .pipe(
        map(val => val ? 1 : -1),
        scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
      )
      .subscribe(
        (res) => {
          if (res === 1) {
            this.showSpinner();
          } else if (res === 0) {
            this.stopSpinner();
          }
        }
      );
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });
  }

  private showSpinner() {
    if (!this.spinnerTopRef.hasAttached()) {
      this.spinnerTopRef.attach(new ComponentPortal(LoaderComponent));
    }

  }

  private stopSpinner() {
    if (this.spinnerTopRef.hasAttached()) {
      this.spinnerTopRef.detach();
    }
  }
}
