import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsLoggedGuard } from './is-logged-guard';

const routes: Routes = [
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  {path: 'main', loadChildren: () => import('./main/main.module').then(m => m.MainModule), canActivate: [IsLoggedGuard]},
  {
    path: 'main/employees/details',
    loadChildren: () => import('./main/employees/details/details.module').then(m => m.DetailsModule),
    canActivate: [IsLoggedGuard]
  },
  {
    path: 'main/projects/details',
    loadChildren: () => import('./main/projects/details/details.module').then(m => m.DetailsModule),
    canActivate: [IsLoggedGuard]
  },
  {
    path: '',
    redirectTo: '/main/employees/list',
    pathMatch: 'full',
    canActivate: [IsLoggedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
